// define actions

var favs = []
var filter = []
 var validFilters = visible_tags.split(/([,;])/); //  /([,;])+/g only filters that are listed in visible_tags (this is a , and ; seperated string, can be set in the theme) are allowed

var actions = {

    setState : function(el , action) {
        this[el][action]()
    },

    getState : function(el) {
        return this[el].state()
    },

    default : {
        state : function(el) {
            return $(el).hasClass('is-open') ? 'open' : 'close';
        },
        open : function(el) {
            $(el).addClass('is-open')
        },
        close : function(el) {
            $(el).removeClass('is-open')
        },
        toggle : function(el) {
            $(el).toggleClass('is-open')
        }
    },

    columnNav : {
        state : function() {
            return $('#wrapper').hasClass('is-nav-open') ? 'open' : 'close';
        },
        open : function() {
            $('#wrapper').addClass('is-nav-open')
            $('.do-toggle-nav').addClass('is-reversed')
            $(window).trigger('resize');
        },
        close : function() {
            $('#wrapper').removeClass('is-nav-open')
            $('.do-toggle-nav').removeClass('is-reversed')
            $(window).trigger('resize');
        },
        toggle : function() {
           'open' === this.state() ? this.close() : this.open()
        }
    },

    columnRight : {
        state : function() {
            return $('#wrapper').hasClass('is-right-open') ? 'open' : 'close';
        },
        open : function() {
            $('#wrapper').addClass('is-right-open')
            // $('.do-toggle-right').addClass('is-reversed')
            $(window).trigger('resize');
        },
        close : function() {
            $('#wrapper').removeClass('is-right-open')
            //location.hash = '';
            // $('.do-toggle-right').removeClass('is-reversed')
        },
        toggle : function() {
           'open' === this.state() ? this.close() : this.open()
        }
    },

    listview : {
        state : function() {
            return $('.photo-list-items').hasClass('is-list-view') ? 'open' : 'close';
        },
        open : function() {
            $('.photolist-items').addClass('is-list-view')
            $('.do-view-list').addClass('is-active')
        },
        close : function() {
            $('.photolist-items').removeClass('is-list-view');
            $('.do-view-list').removeClass('is-active');
        },
        toggle : function() {
           'open' === this.state() ? this.close() : this.open()
        }
    },

    gridview : {
        state : function() {
            return $('.photo-list-items').hasClass('is-grid-view') ? 'open' : 'close';
        },
        open : function() {
            $('.photolist-items').addClass('is-grid-view')
            $('.do-view-grid').addClass('is-active')
        },
        close : function() {
            $('.photolist-items').removeClass('is-grid-view');
            $('.do-view-grid').removeClass('is-active');
        },
        toggle : function() {
           'open' === this.state() ? this.close() : this.open()
        }
    },

    filterTag: {
        state : function(el) {
            return $(el).hasClass('is-checked') ? 'checked' : 'unchecked';
        },
        check : function(el) {
            $(el).addClass('is-checked')
        },
        uncheck : function(el) {
            $(el).removeClass('is-checked');
        },
        toggle : function(el) {
           'checked' === this.state(el) ? this.uncheck(el) : this.check(el)
        }
    },

    filterList : {
        updateFromHash : function() {
            filter = [];
            var hashArray = location.hash.replace("#","").split(':');
            if (hashArray[0] != 'filter') {
                return
            }
            filter = [];
            var filterFromHash = hashArray[1].split(','); //remove # and split hash on ,
            for (var i=0; i<filterFromHash.length; i++) {
              if (false !== findByValue(validFilters , filterFromHash[i] )) {
                filter.push(filterFromHash[i]) // this is a valid filter from hash => add it to the filter
              }
            }
            actions.filterList.update(); // update the filter
            actions.slider.load(); // update the filter
        },
        initialLoadFromHash : function() {
            if (location.hash) {
                var hashArray = location.hash.replace("#","").split(':');
                if (hashArray.length < 2 || hashArray[0] != 'filter') {
                    return
                }
                filter = [];
                var filterFromHash = hashArray[1].split(','); //remove # and split hash on ,
                // check if hash items exists in validFilters array
                for (var i=0; i<filterFromHash.length; i++) {
                  if (false !== findByValue(validFilters , filterFromHash[i] )) {
                    filter.push(filterFromHash[i]) // this is a valid filter from hash => add it to the filter
                  }
                }
                actions.filterList.update(); // update the filter
                if (filter.length) {
                    $('.do-open-photolist').eq(0).trigger('click'); // not so nice, but the easiest way to open photolist
                }
            }
        },
        add: function(tag){
            filter.push(tag)
            this.update()
            //actions.filterTag.check('.filter-tag[data-tag="'+tag+'"]');
        },
        remove: function(tag) {
            i = findByValue(filter, tag)
            filter.splice(i , 1)
            actions.filterTag.uncheck($('.filter-tag[data-tag="'+tag+'"]'));
            this.update()
        },
        set : function(tag) {
            if (!(0 == tag.length)) {
                filter.length = 0
                tag = tag.split(',');
                for(var i=0; i<tag.length; i++) {
                    this.add(tag[i])
                }
                this.update()
            }
        },
        toggle : function(tag) {
            (false !== findByValue(filter, tag)) ? this.remove(tag) : this.add(tag)
        },
        update : function() {
            var hash = '#filter:'+filter.join(',')
            location.hash = hash;
            //history.pushState(null, null, hash);
            actions.filterTag.uncheck($('.filter-tag'));
            $.each(filter, function(i, v) {
                actions.filterTag.check('.filter-tag[data-tag="'+v+'"]');
            })
            $('.filter-counter').html(filter.length)
            $('.filter-counter').toggleClass('is-active' , filter.length ? true : false);
            $('.do-toggle-filter').toggleClass('is-active' , filter.length ? true : false);
            $('.filter-preview').html(' ').html(filter.join(', '))
            actions.photolist.load();

            // track google analytics
            _gaq.push(['_trackEvent', 'Filter', 'Update', filter.join(',')])
        }
    },
    photolist : {
        load : function() {
            $('#photolist-items').addClass('is-loading');
            $.get( "albums/home/", {'tags' : filter.join(',') , 'return' : 'list'} , function(result) {
                $('#photolist-items').html(result);
                $(window).trigger('resize');
                $.event.trigger({type: "photolist-ready"});
                 $('#photolist-items').removeClass('is-loading');
            })
            .fail(function() {
                $('#photolist-items').html('Fout bij het laden, probeer opnieuw');
            })
        }
    },
    favoriteItem : {
        state : function(el) {
            return $(el).parent().hasClass('is-fav') ? 'fav' : false;
        },
        check : function(el) {
            $(el).parent().addClass('is-fav');
        },
        uncheck : function(el) {
            $(el).parent().removeClass('is-fav');
        }
    },
    favoriteList : {
        load : function() {
            (undefined !== $.cookie('favs')) ? favs = JSON.parse($.cookie('favs')) : favs = [];
            $.each(favs, function(i, v) {
                actions.favoriteItem.check($('.imagecell-fav[data-img-id='+v+']'));
            })
            $('.favorites-counter').html(favs.length)
        },
        save : function() {
            $('.favorites-counter').html(favs.length)
            $('.favorites-counter').toggleClass('is-active' , favs.length ? true : false);
            $.cookie('favs' , JSON.stringify(favs) , { expires: 365, path: '/' })
        },
        add: function(el){
            actions.favoriteItem.check(el)
            favs.push($(el).data('img-id'))
            _gaq.push(['_trackEvent', 'Favorite', 'Add', $(el).data('img-id').toString()]  )
            this.save()
        },
        remove: function(el) {
            actions.favoriteItem.uncheck(el)
            i = findByValue(favs, $(el).data('img-id'))
            favs.splice(i , 1)
             _gaq.push(['_trackEvent', 'Favorite', 'Remove', $(el).data('img-id').toString()]  )
            this.save()
        },
        toggle : function(el) {
            (false !== findByValue(favs, $(el).data('img-id'))) ? this.remove(el) : this.add(el)
        }

    },
    favoriteShow : {
        state : function() {
            return $('.do-toggle-favorites').hasClass('is-active') ? 'show' : 'hidden';
        },
        show : function() {
            $('.do-toggle-favorites').addClass('is-active')
            $('.imagecell:not(.is-fav)').addClass('is-hidden');
            _gaq.push(['_trackEvent', 'PhotoList' , 'Favorite show'])
        },
        hide : function() {
            $('.do-toggle-favorites').removeClass('is-active')
            $('.imagecell').removeClass('is-hidden');
             _gaq.push(['_trackEvent', 'PhotoList' , 'Favorite show'])
        },
        toggle : function() {
            'show' === this.state() ? this.hide() : this.show()
        }
    },
    slider : {
        load : function() {
            $('#slider').addClass('is-open')
            $('#logo').addClass('is-fadeout')
            $K.pulse.teardown();
            $('#slider').addClass('is-loading');
            $.get( "albums/home/", {'tags' : filter.join(',') , 'return' : 'slider'} , function(result) {
                $('#slider').html(result).addClass('is-loaded');
                $K.ready();
                $.event.trigger('slider-ready');
                $('#slider').removeClass('is-loading');
                $K.keyboard.bind();
            })
            .fail(function() {
                console.log( "error loading filter" );
            })
        },
        isLoaded : function() {
            return $('#slider').hasClass('is-loaded');
        },
        state : function() {
            return $('#slider').hasClass('is-open') ? 'open' : 'close';
        },
        open : function() {
            $('#slider').addClass('is-open')
            $('#logo').addClass('is-fadeout')
        },
        close : function() {
            $('#slider').removeClass('is-open')
            $('#logo').removeClass('is-fadeout')
        },
        toggle : function() {
            'open' === this.state() ? this.close() : this.open()
        }
    }
}

function findByValue(arr, val) {
    for(var i=0; i<arr.length; i++) {
        if( arr[i] == val ) {
            return i;
        }
    }
    return false
}


// create filter tags and set allowed filters

var tags_groups = visible_tags.split(';');

$.each(tags_groups , function(i , group ){

    var tags_array = group.split(',');

    $.each(tags_array , function(i , o ){
       $('<li>'+
            '<a href="#" data-tag="'+o+'" class="filter-tag do-toggle-tag"><span class="icon-ico-square menu-icon"></span>'+o+'</a>'+
        '</li>').appendTo('#filter_list')
    })

    $('<li class="filter-spacer"></li>').appendTo('#filter_list');

})

var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

actions.default.close('.navigation');


$('.sidebar-icon').click(function() {
    $('.sidebar-icon').removeClass('is-active');
    $(this).addClass('is-active')
})

$('.do-open-about').click(function(e){
    e.preventDefault();
    actions.default.open('.about');
    actions.default.close('.timeline');

    $('.sidebar-icon').removeClass('is-active');
    $('.do-open-about').addClass('is-active')
    actions.columnRight.open();
    _gaq.push(['_trackEvent', 'PageView', 'About']  )
})

$('.do-open-photolist').click(function(e) {
    e.preventDefault();
    $('.sidebar-icon').removeClass('is-active');
    $(this).addClass('is-active');
    actions.columnNav.open();
    actions.default.open('.photolist');
    actions.default.close('.navigation');
    actions.default.close('.contact');
    actions.columnRight.close();
    actions.default.close('#home');
    if (!$('#slider').hasClass('is-loaded')) {
        actions.slider.load()
    } else {
        actions.slider.open();
    }
})

$('.do-open-tag').click(function(e){
    e.preventDefault()
    filter.length = 0
    actions.filterList.add($(this).data('tag'));
    actions.columnNav.open();
    actions.default.open('.photolist');
    actions.default.close('.navigation');
    actions.default.close('.contact');
    actions.columnRight.close();
    actions.default.close('#home');
    actions.slider.load()
})

// bind click events to state changes
$('.do-toggle-nav').click(function(e){
    e.preventDefault();
    actions.columnNav.toggle();
})

$('.do-open-right').click(function(e){
    e.preventDefault();
    actions.columnRight.open(e);

})

$(".column--right").clickoutside(function(e){
    if (!$(e.target).hasClass('do-open-right') && !$(e.target).hasClass('do-open-about') && !$(e.target).hasClass('do-open-timeline')) {
        actions.columnRight.close(e);
    }
})


$('.do-open-home').click(function(e){
    e.preventDefault();
    actions.default.open('#home');
    actions.slider.close();
    actions.columnNav.close();
    actions.columnRight.close();
    location.hash = '';
    _gaq.push(['_trackEvent', 'PageView', 'Home']  )
    // filter = [];
    // actions.filterList.update();
})


$('.tile > a').click(function() {
    _gaq.push(['_trackEvent', 'HomePageLamel', 'Click' , $(this).data('tag')])
})

$('.do-toggle-right').click(function(e){
    e.preventDefault();
    actions.columnRight.toggle(e);
})

$('.do-toggle-tag').click(function(e){
    e.preventDefault();
    actions.filterList.toggle($(this).data('tag'));
    actions.slider.load();
    actions.default.close('#home')
   // actions.slider.open();
})

$('.do-open-timeline').click(function(e){
    e.preventDefault();
    actions.default.open('.timeline');
    actions.default.close('.about');
    actions.columnRight.open();
    actions.columnNav.close();
    $K.ready();
    _gaq.push(['_trackEvent', 'PageView', 'Timeline']  )
})



// list views
$('.do-view-list').click(function(e){
    e.preventDefault();
    actions.gridview.close()
    actions.listview.open()
    _gaq.push(['_trackEvent', 'PhotoList', 'Toggle list layout']  )
})

$('.do-view-grid').click(function(e){
    e.preventDefault();
    actions.listview.close()
    actions.gridview.open()
    _gaq.push(['_trackEvent', 'PhotoList', 'Toggle grid layout']  )
})


$('.do-toggle-filter').click(function(e){
    e.stopPropagation();
    e.preventDefault();
    actions.default.toggle('.filter');
})

$(".filter").clickoutside(function(e) {
    if (!$(e.target).hasClass('do-toggle-filter')) {
       actions.default.close('.filter');
    }
})

$('.do-open-navigation').click(function(e){
    e.preventDefault();
    actions.columnNav.open();
    actions.default.open('.navigation');
    actions.default.close('.photolist');
    actions.default.close('.contact');
})

$('.do-open-contact').click(function(e){
    e.preventDefault();
    actions.columnNav.open();
    actions.default.close('.column--nav .is-open');
    actions.default.open('.contact');
    _gaq.push(['_trackEvent', 'PageView', 'Contact']  )
})

$('.do-toggle-favorites').click(function(e){
    e.preventDefault();
    actions.favoriteShow.toggle()
})

// bind photolist click event, only on initial, when slider is not loaded yet
$('.do-load-img').off().click(function(e){
    if (!$('#slider').hasClass('is-loaded')) {
        actions.slider.load()
        actions.default.close('#home');
    } else {
        actions.slider.open();
    }
    var $t = $(this)
    $(window).one('slider-ready' , function(){
        pulse.on('ready' , function() {
            pulse.loadItemAtPosition($t.data('img-pos'));
        })
    })
})


pulse = null

// bind photolist click event when slider is ready
$(window).on('slider-ready' , function(){
    if( null !== pulse) {
        pulse.on('ready' , function() {
            $('.do-load-img').off().click(function(e){
                actions.slider.open();
                actions.default.close('#home');
                e.preventDefault();
                _gaq.push(['_trackEvent', 'PhotoList', 'Click', $(this).data('img-id')])
                pulse.loadItemAtPosition($(this).data('img-pos'));
            })
        })
        pulse.on('waiting', function(){
            $('#slider').addClass('is-loading');
        })
        pulse.on('transitionstart', function(){
            $('#slider').removeClass('is-loading');
        })

        pulse.on('item', function(e) {
            _gaq.push(['_trackEvent', 'SlideShow', 'ViewImage', e.data.id.toString()])
        })
    }
})


// bind favorite click event when photolist is ready
$(document).on('photolist-ready', function() {
    actions.favoriteList.load()
    $('.do-add-favorite').off().click(function(e){
        e.preventDefault();
        actions.favoriteList.toggle(this)
    })
    if ($(window).width() < 768 ) {
        $('#photolist').css( 'width' , ($('.imagecell').length*100));
    }
})
$.event.trigger({type: "photolist-ready"});


// silly fix for lazy loading image bug in koken
$(".column--right").scroll( function(){
   $(window).trigger('resize')
})

$(document).ready(function(){
    // trigger photolist open when there is a hash set on load

    actions.filterList.initialLoadFromHash();

    // trigger timeline open on load
    var hashArray = location.hash.replace("#","").split(':');
    if (hashArray[0] == 'timeline') {
        $('.do-open-timeline').trigger('click');
    }

    $('.timeline-datetime').click(function(){
        location.hash = '#timeline:'+$(this).data('id')
    })
})

// contact form submit
$("#contact").submit(function(e) {
    e.preventDefault();
    var data = $("#contact").serialize();
    $.post("8SIOD76SUDI.php" , data, function(response) {
        if (response) {
            $(".response").html(response.msg).fadeIn(300);
        }
    }).fail(function(){
        $(".response").html('unknown error');
    });
})



